import React, { useState, useRef } from "react";
import { CSSTransition } from 'react-transition-group';
import "./style.css";
// import styled from "styled-components";


function Experience(props) {

  // const Div = styled.div.attrs('description')`
  // margin-top: -60px`

  const [showButton, setShowButton] = useState(true);
  const [hover, setHover] = useState(false);
  const nodeRef = useRef(null);
  const onMouseOverHandler = (e) => {
    if (hover === false) {
      setHover(true);
    }
    else {
      setHover(false);
    }
  };


  const [hover1, setHover1] = useState(false);
  const onMouseOverHandler1 = (e) => {
    if (hover1 === false) {
      setHover1(true);
    }
    else {
      setHover1(false);
    }
  };

  const [hover2, setHover2] = useState(false);
  const onMouseOverHandler2 = (e) => {
    if (hover2 === false) {
      setHover2(true);
    }
    else {
      setHover2(false);
    }
  };

  const [hover3, setHover3] = useState(false);
  const onMouseOverHandler3 = (e) => {
    if (hover3 === false) {
      setHover3(true);
    }
    else {
      setHover3(false);
    }
  };

  const [hover4, setHover4] = useState(false);
  const onMouseOverHandler4 = () => {
    if (hover4 === false) {
      setHover4(true);
    }
    else {
      setHover4(false);
    }
  };

  const [hover5, setHover5] = useState(false);
  const onMouseOverHandler5 = () => {
    if (hover5 === false) {
      setHover5(true);
    }
    else {
      setHover5(false);
    }
  };

  const content = <div className="exp-body">
    <div className="experience">
        {hover?<div className="exp-com" style={{'background-color': '#2fa3b7', 'color': 'white', 'border-top-left-radius': '30px'}}>
        <button>1</button>
        <div onClick={onMouseOverHandler}>
            <div className="role">Network Engineer </div>
            <div className="company">NetPlus IT Solutions (IT Solutions Company in Myanmar)</div>
        </div>
        </div>:<div className="exp-com">
        <button>1</button>
        <div onClick={onMouseOverHandler}>
            <div className="role">Network Engineer </div>
            <div className="company">NetPlus IT Solutions (IT Solutions Company in Myanmar)</div>
        </div>
        </div>}
        {showButton && (hover)}
        <CSSTransition
        in={hover}
        nodeRef={nodeRef}
        timeout={1500}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
        >
        <div ref={nodeRef} className="desc">
            <div className="from-to">
            <div className="date-from"><div className="from">From</div><div className="date">:&nbsp; May 2014 </div></div>
            <div className="date-to"><div className="to"> To</div><div className="date">:&nbsp;  August 2016 </div></div>
            </div>
            <div className="respon-desc">
                <div className="respon">Responsibilities :</div>
                <ul>
                <li id="1">Installed and configured network equipment and responsible for installing and configuring network hardware such as routers, switches, and Wireless Controllers, Access points etc.</li>
<li id="2">• Facilitated in problem solving and collaboration.</li>
<li id="3">• Designed network architecture including VLAN, LAN, WAN and remote networks.</li>
<li id="4">• Provided network support and troubleshooting for customers and user.</li>
<li id="5">• Managed the smooth delivery of network operation according with SLA.</li>
<li id="6">• Troubleshot network inside the broadband network and solved the network issues.</li>
<li id="7">• Assisted senior engineers in upgrading the network hardware and software as and when required.</li>
<li id="8">• Troubleshot configuration for all branches and HQ’ clients such as routing & switching, IPSec VPN, VLANs, STP, HSRP, Ether channel.</li>
<li id="9">• Responsible for day-to-day operation issues.</li>
<li id="10">• Accomplished and documented related activities network configurations, including IP addressing, network typologies, and hardware and software version.</li>
                </ul>
            </div>
        </div>
        </CSSTransition>
    </div>
    
    <div className="experience">
        {hover2?<div className="exp-com" style={{'background-color': '#2fa3b7', 'color': 'white', 'border-top-left-radius': '30px'}}>
        <button>2</button>
        <div onClick={onMouseOverHandler2}>
            <div className="role">Sr.Network Engineer </div>
            <div className="company">Alliance for Microfinance in Myanmar (Top MFI in Myanmar)</div>
        </div>
        </div>:<div className="exp-com">
        <button>2</button>
        <div onClick={onMouseOverHandler2}>
            <div className="role">Sr.Network Engineer </div>
            <div className="company">Alliance for Microfinance in Myanmar (Top MFI in Myanmar)</div>
        </div>
        </div>}
        
        {showButton && (hover2)}
        <CSSTransition
        in={hover2}
        nodeRef={nodeRef}
        timeout={1500}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
        >
        <div ref={nodeRef} className="desc">
            <div className="from-to">
            <div className="date-from"><div className="from">From</div><div className="date">:&nbsp;  September 2016  </div></div>
            <div className="date-to"><div className="to"> To</div><div className="date">:&nbsp;  February 2023 </div></div>
            </div>
            <div className="respon-desc">
                <div className="respon">Responsibilities :</div>
                <ul>
                <li>•	Plan and design computer networks, including LAN, WAN, SDWAN, IPsec VPN, and SSL VPN.</li>
<li>•	Install and configure network equipment such as routers, switches, firewalls, and other hardware devices.</li>
<li>•	Provide support for network operations, problem isolation, and troubleshooting network incidents.</li>
<li>•	Implement security measures to protect the network from cyber threats, including firewalls, intrusion detection systems, and encryption protocols.</li>
<li>•	Monitor network performance and troubleshoot issues to ensure optimal performance and uptime via Zabbix, Cacti NMS.</li>
<li>•	Diagnose and resolve network problems, such as connectivity issues, configuration errors, and performance bottlenecks.</li>
<li>•	Perform routine maintenance tasks, such as software updates, hardware upgrades, and system backups.</li>
<li>•	Create and maintain documentation for network configurations, procedures, and troubleshooting guidelines.</li>
<li>•	Work closely with other IT professionals, such as system administrators, security analysts, and software developers, to ensure the smooth operation of the network.</li>
<li>•	Offer installation and technical support to end-users.</li>
<li>•	Be proficient in Microsoft Windows Server Operating System, TCP/IP, DNS, and adept at troubleshooting system issues.</li>
<li>•	Analyze requirements and execute network solutions, planning, installation, and setup of network systems.</li>
<li>•	Install and configure cloud services such as Microsoft 365, Microsoft Azure AD, Intune.</li>

                </ul>
            </div>
        </div>
        </CSSTransition>
    </div>
    <div className="experience">
        {hover3?<div className="exp-com" style={{'background-color': '#2fa3b7', 'color': 'white', 'border-top-left-radius': '30px'}}>
        <button>3</button>
        <div onClick={onMouseOverHandler3}>
            <div className="role">Sr.Network & System administrator </div>
            <div className="company">Sun Community Health(A Large Healthcare Services-NGO in Myanmar)</div>
        </div>
        </div>:<div className="exp-com">
        <button>3</button>
        <div onClick={onMouseOverHandler3}>
            <div className="role">Sr.Network & System administrator </div>
            <div className="company">Sun Community Health(A Large Healthcare Services-NGO in Myanmar)</div>
        </div>
        </div>}
        
        {showButton && (hover3)}
        <CSSTransition
        in={hover3}
        nodeRef={nodeRef}
        timeout={1500}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
        >
        <div ref={nodeRef} className="desc">
            <div className="from-to">
            <div className="date-from"><div className="from">From</div><div className="date">:&nbsp;   February 2023</div></div>
            <div className="date-to"><div className="to"> To</div><div className="date">:&nbsp;  Current </div></div>
            </div>
            <div className="respon-desc">
                <div className="respon">Responsibilities :</div>
                <ul>
                <li>•	Coordinate the acquisition, development, and maintenance of digital and telecommunication systems, including Switching VLANs, STP, EtherChannel, and routing OSPF,BGP,EIGRP.</li>
<li>•	Lead the technical team to provide support for system, infrastructure, and digitization projects.</li>
<li>•	Install and configure client computer hardware, security devices, and applications.</li>
<li>•	Troubleshoot system, network, and security issues, resolving hardware or software faults.</li>
<li>•	Monitor and maintain computer systems, networks, and security devices such as AD,NAC.</li>
<li>•	Monitor security alerts, analyze incidents, and escalate issues as needed.</li>
<li>•	Implement and maintain network security solutions, including firewalls and intrusion detection systems.</li>
<li>•	Prepare and update documentation, user guides, and standard operating procedures (SOPs).</li>
<li>•	Manage vulnerability and patch management for networks and systems.</li>
<li>•	Lead the IT team in system and network deployment, including monitoring Microsoft O365 for security enhancements.</li>
                </ul>
            </div>
        </div>
        </CSSTransition>
    </div>
    
  </div>

  return (
    <div className="exp">
      <div className="exp-content">
      {/* {props.type === true? content :<Div>{content}</Div>} */}
      {content}
      </div>
    </div>
  );
}
export default Experience;
